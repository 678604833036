import { useState } from 'react'
import useSWR, { mutate } from 'swr'
import { getStampedReviewsByProductId__client } from '~/api/stamped'
import { StampedReviewSchema } from '~/features/product/Product-Reviews/fragments'

const makeKey = (id: string, page?: number, count?: number) =>
  [id, page ?? 1, count ?? 5, 'stamped-reviews'] as const

export const useStampedReviews = (
  productId: string,
  options: {
    initialData?: Partial<StampedReviewSchema>[]
    count: number
  }
) => {
  const [page, setPage] = useState(1)

  const { data, error, isValidating } = useSWR(
    makeKey(productId, page, options.count),
    async () => {
      await new Promise((resolve) => setTimeout(resolve, 6000)); // 2 seconds delay
      const result = await getStampedReviewsByProductId__client(productId, {
        page,
        limit: options.count,
        sortBy: 'highest-rating',
      })

      // console.log('[stamped] swr', result, productId)

      if (!result) throw new Error('failed to get stamped reviews')

      mutate(makeKey(productId, page + 1, options.count))

      return result.data
    },
    {
      ...(page === 1 && { initialData: options.initialData }),
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 0,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
    }
  )
  return {
    page,
    setPage,
    data,
    error,
    isValidating,
  }
}
