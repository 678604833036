/** @jsxImportSource theme-ui */
import { jsx } from 'theme-ui'
import React, { useState } from 'react'
import { StampedReviewSchema } from './Product-Reviews/fragments'
import moment from 'moment'
import { Box, Container, Flex, Text } from 'theme-ui'
import { MdCheckCircle } from 'react-icons/md'
import { StarRatings } from './Star-Ratings'
import { useTheme } from 'src/hooks/use-theme'
import { ShopName } from 'src/constants'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import { ProductReviewsLightbox } from './Product-Reviews-Lightbox'
import { empty } from 'src/templates/empty'
import { Styled } from '~/components/styled'

type Props = {} & StampedReviewSchema

export const ProductReviewItem = React.memo(function ProductReviewItem(
  props: Props
) {
  const {
    reviewRating,
    reviewDate,
    reviewMessage,
    reviewUserPhotos,
    author,
    reviewVerifiedType,
    reviewTitle,
  } = props

  const photoUrls = reviewUserPhotos?.split(',')

  const [modalOpenIndex, setModalOpenIndex] = useState<null | number>(null)

  const isVerifiedBuyer = reviewVerifiedType === 2
  const date = moment(reviewDate).fromNow()

  const { colors } = useTheme()

  if (!reviewRating) return null

  return (
    <Container sx={{ paddingX: 3 }}>
      <Box
        sx={{
          p: [3, 4],
          bg: 'background',
          borderRadius: 3,
          borderStyle: 'solid',
          mb: 3,
        }}
      >
        <Flex sx={{ display: ['block', 'flex'] }}>
          <Box
            sx={{
              marginRight: [0, 4],
              flex: [null, 1],
              display: ['flex', 'block'],
              justifyContent: ['space-between'],
            }}
          >
            <Styled.h3 sx={{ fontWeight: '600' }}>{author}</Styled.h3>
            {isVerifiedBuyer && (
              <Box sx={{ color: 'secondary' }}>
                <Flex sx={{ alignItems: 'center' }}>
                  <MdCheckCircle sx={{ mb: '3px' }} />
                  <Box sx={{ flex: 1, ml: 1 }}>
                    <Text>Verified Buyer</Text>
                  </Box>
                </Flex>
              </Box>
            )}
            {/* <Text sx={{ color: 'gray' }}>{location}</Text> */}
          </Box>
          <Box sx={{ marginLeft: [0, 4], flex: [null, 4] }}>
            <Flex sx={{ marginBottom: 4 }}>
              <StarRatings
                sx={{ color: 'secondary' }}
                size={25}
                color={colors.secondary}
                rating={reviewRating}
              />
              <Text sx={{ color: 'gray', marginLeft: 2 }}>{date}</Text>
            </Flex>
            <Box
              sx={{
                display: ['block', 'flex'],
                justifyContent: ['space-between'],
              }}
            >
              <Styled.h2 sx={{ fontWeight: '500' }}>{reviewTitle}</Styled.h2>
            </Box>
            <Box>
              <Text>{reviewMessage}</Text>
            </Box>
          </Box>
        </Flex>
        {photoUrls?.filter(Boolean).map((photo, i) => {
          return (
            <LazyLoadImage
              alt={`${ShopName} product review by ${author}`}
              key={photo}
              src={`//cdn.stamped.io/uploads/photos/${photo}`}
              sx={{
                // height: 60,
                width: 100,
                borderRadius: 3,
                overflow: 'hidden',
                mr: i < photoUrls.length - 1 ? 2 : 0,
                objectFit: 'cover',
                mt: [3, 4],
                mb: 0,
                maxHeight: 200,
                cursor: 'pointer',
              }}
              onClick={() => setModalOpenIndex(i)}
            />
          )
        })}
        {modalOpenIndex !== null && (
          <ProductReviewsLightbox
            data={photoUrls?.map((photo) => ({
              src: `//cdn.stamped.io/uploads/photos/${photo}`,
              reviewTitle,
              reviewMessage,
            }))}
            initialIndex={modalOpenIndex}
            onClose={() => setModalOpenIndex(null)}
          />
        )}
      </Box>
    </Container>
  )
})
