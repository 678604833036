import React from 'react'
import { useCreateStampedQuestion } from 'src/hooks/use-create-stamped-question'
import { useTheme } from 'src/hooks/use-theme'
import {
  Box,
  Button,
  Container,
  Flex,
  Input,
  Label,
  Text,
  Textarea,
} from 'theme-ui'

type Props = {} & Parameters<typeof useCreateStampedQuestion>[0]

export function ProductQuestionCreator(props: Props) {
  const {
    createQuestion,
    questionState,
    createQuestionError,
    creatingQuestion,
    setQuestionState,
    isCreatingQuestion,
    setIsCreatingQuestion,
    createdQuestionSuccessfully,
  } = useCreateStampedQuestion(props)

  const { colors } = useTheme()

  const { name, email, reviewBody } = questionState

  const renderForm = () => {
    if (!isCreatingQuestion && !createdQuestionSuccessfully) return null
    return (
      <Box
        sx={{
          bg: 'background',
          borderRadius: 3,
          borderStyle: 'solid',
          p: 3,
          marginTop: 3,
        }}
      >
        <Input
          role="name"
          type="name"
          value={name}
          onChange={(e) =>
            setQuestionState((state) => ({ ...state, name: e.target.value }))
          }
          placeholder="Enter your name"
          sx={{ marginBottom: 3, borderWidth: 1, borderColor: 'text' }}
        />
        <Input
          role="Email"
          type="email"
          value={email}
          onChange={(e) =>
            setQuestionState((state) => ({ ...state, email: e.target.value }))
          }
          placeholder="Enter your email"
          sx={{ marginBottom: 3, borderWidth: 1, borderColor: 'text' }}
        />
        <Textarea
          role="Question Message"
          value={reviewBody}
          onChange={(e) =>
            setQuestionState((state) => ({
              ...state,
              reviewBody: e.target.value,
            }))
          }
          placeholder="Ask your question here"
        ></Textarea>
        <Box sx={{ my: 2 }}>
          {createQuestionError && (
            <Text sx={{ color: 'accent' }}>
              Error: {createQuestionError.message ?? 'Please try again'}.
            </Text>
          )}
          {createdQuestionSuccessfully && (
            <Text sx={{ color: 'secondary' }}>
              Thanks for your question!{' '}
              {`You'll receive an email shortly with our response.`}
            </Text>
          )}
          <Button
            disabled={creatingQuestion || createdQuestionSuccessfully}
            onClick={createQuestion}
            sx={{
              bg: createdQuestionSuccessfully ? 'secondary' : null,
              borderColor: createdQuestionSuccessfully ? 'secondary' : null,
            }}
          >
            {createdQuestionSuccessfully
              ? 'Success!'
              : creatingQuestion
              ? 'Submitting...'
              : `Submit Question`}
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <Container>
      <Box sx={{ marginY: 4, marginX: [0, 3] }}>
        <Button
          variant="outline"
          onClick={() => setIsCreatingQuestion((state) => !state)}
          sx={{ mx: [3, 0] }}
        >
          Ask a question
        </Button>
        {renderForm()}
      </Box>
    </Container>
  )
}
