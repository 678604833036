import { useState } from 'react'
import { useAsyncCallback } from 'react-async-hook'

const urlencoded = new URLSearchParams()
urlencoded.append('productId', '4024388163')
urlencoded.append('apiKey', '{{apiKeyPublic}}')
urlencoded.append('sId', '{{storeHash}}')
urlencoded.append('name', 'John Doh')
urlencoded.append('email', 'john.doh@example.com')
urlencoded.append('questionBody', 'This is a test question')
urlencoded.append('productName', "Product's Title")
urlencoded.append('productSKU', "Product's SKU")
urlencoded.append('productUrl', 'https://example.com/products/product-image.')
urlencoded.append(
  'productImageUrl',
  'https://example.com/products/product-image.'
)

type State = {
  email: string
  name: string
  reviewBody: string
  /**
   * Store hash: process.env.STAMPED_HASH
   */
  sId: string
  apiKey: string
}
const aliases: { [k in keyof State]?: string } = {
  reviewBody: 'Question',
  email: 'Email',
  name: 'Name',
}

type Props = {
  /**
   * String version of the # of the shopify product ID. Get this from page context.
   */
  productId: string
  /**
   * Product HANDLE
   */
  productSKU: string
  productName: string
  // onSuccess: () => void
}

const initialState: State = {
  email: '',
  name: '',
  reviewBody: '',
  apiKey: process.env.NEXT_PUBLIC_STAMPED_KEY as string,
  sId: process.env.NEXT_PUBLIC_STAMPED_HASH as string,
}

export const useCreateStampedQuestion = ({ ...props }: Props) => {
  // https://developers.stamped.io/?version=latest#94122b0e-3883-461f-9c00-55051742a512
  const endpoint = `https://stamped.io/api/questions`

  const [state, setState] = useState<State>(initialState)
  const [isCreatingQuestion, setIsCreatingQuestion] = useState(false)
  const [createdQuestionSuccessfully, setCreatedQuestionSuccessfully] =
    useState(false)

  const { execute, loading, error } = useAsyncCallback(
    async () => {
      const body = new URLSearchParams()

      const missingFields: string[] = []

      Object.entries(state).forEach(([key, value]) => {
        if (!value && aliases[key as keyof State]) {
          missingFields.push(aliases[key as keyof State] as string)
        }

        body.append(key, value)
      })
      if (missingFields.length) {
        throw new Error(
          `Make sure you fill out every field. These were missing: ${missingFields.join(
            ', '
          )}`
        )
      }
      Object.entries(props).forEach(([key, value]) => {
        body.append(key, value)
      })

      const response = await fetch(endpoint, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        // body: JSON.stringify({
        //   ...state,
        //   ...props,
        // }),
        body,
        method: 'POST',
        redirect: 'follow',
      })

      // // console.log('[create-stamped-question] success', await response.text())

      return
    },
    {
      onSuccess: () => {
        setState(initialState)
        setIsCreatingQuestion(false)
        setCreatedQuestionSuccessfully(true)
      },
    }
  )

  return {
    createQuestion: execute,
    creatingQuestion: loading,
    createQuestionError: error,
    questionState: state,
    setQuestionState: setState,
    isCreatingQuestion,
    setIsCreatingQuestion,
    createdQuestionSuccessfully,
  }
}
