/** @jsxImportSource theme-ui */
import { jsx } from 'theme-ui'
import { useState } from 'react'
import Lightbox from 'react-image-lightbox'

type Props = {
  data?: { src: string; reviewMessage?: string; reviewTitle?: string }[]
  initialIndex: number
  onClose: () => void
}

export function ProductReviewsLightbox(props: Props) {
  const { data, initialIndex, onClose } = props
  const [index, setIndex] = useState(initialIndex)

  if (!data) return null
  const src = data[index]?.src ?? ''

  return (
    <Lightbox
      mainSrc={src}
      clickOutsideToClose
      enableZoom
      animationDisabled
      nextSrc={data[(index + 1) % data.length]?.src}
      prevSrc={data[(index + data.length - 1) % data.length]?.src}
      onCloseRequest={onClose}
      onMovePrevRequest={() =>
        setIndex((index + data.length - 1) % (data?.length ?? 1))
      }
      onMoveNextRequest={() => setIndex((index + 1) % data.length)}
      imageCaption={data[index]?.reviewMessage}
      imageTitle={data[index]?.reviewTitle}
    />
  )
}
