import { useState } from 'react'
import { useAsyncCallback } from 'react-async-hook'

// const urlencoded = new URLSearchParams()
// urlencoded.append('productId', '4024388163')
// urlencoded.append('author', 'John Doh')
// urlencoded.append('email', 'john.doh@example.com')
// urlencoded.append('location', 'United States')
// urlencoded.append('reviewRating', '5')
// urlencoded.append('reviewTitle', 'This is a test review title')
// urlencoded.append('reviewMessage', 'This is a test review message')
// urlencoded.append('reviewRecommendProduct', 'true')
// urlencoded.append('productName', "Product's Title")
// urlencoded.append('productSKU', "Product's Sku")
// urlencoded.append(
//   'productImageUrl',
//   'https://example.com/image/product-image.png'
// )
// urlencoded.append('productUrl', 'https://example.com/products/product-image.')
// urlencoded.append('reviewSource', ' api')

type State = {
  email: string
  author: string
  reviewRating: '1' | '2' | '3' | '4' | '5'
  reviewTitle: string
  reviewMessage: string
  location: 'United States'
  reviewRecommendedProduct: 'true'
  reviewSource: 'api'
}
const aliases: { [k in keyof State]?: string } = {
  reviewRating: 'Review Rating',
  reviewTitle: 'Review Title',
  reviewMessage: 'Review Message',
  email: 'Email',
  author: 'Name',
}

type Props = {
  /**
   * String version of the # of the shopify product ID. Get this from page context.
   */
  productId: string
  /**
   * Product HANDLE
   */
  productSKU: string
  productName: string
  // onSuccess: () => void
}

const initialState: State = {
  email: '',
  author: '',
  reviewRating: '5',
  reviewTitle: '',
  reviewMessage: '',
  location: 'United States',
  reviewRecommendedProduct: 'true',
  reviewSource: 'api',
}

export const useCreateStampedReview = ({ ...props }: Props) => {
  const endpoint = `https://stamped.io/api/reviews2?apiKey=${process.env.NEXT_PUBLIC_STAMPED_KEY}&sId=${process.env.STAMPED_HASH}`

  const [state, setState] = useState<State>(initialState)
  const [isCreatingReview, setIsCreatingReview] = useState(false)
  const [createdReviewSuccessfully, setCreatedReviewSuccessfully] =
    useState(false)

  const { execute, loading, error } = useAsyncCallback(
    async () => {
      const body = new URLSearchParams()

      const missingFields: string[] = []

      Object.entries(state).forEach(([key, value]) => {
        if (!value && aliases[key as keyof State]) {
          missingFields.push(aliases[key as keyof State] as string)
        }

        body.append(key, value)
      })
      if (missingFields.length) {
        throw new Error(
          `Make sure you fill out every field. These were missing: ${missingFields.join(
            ', '
          )}`
        )
      }
      Object.entries(props).forEach(([key, value]) => {
        body.append(key, value)
      })

      const response = await fetch(endpoint, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        // body: JSON.stringify({
        //   ...state,
        //   ...props,
        // }),
        body,
        method: 'POST',
        redirect: 'follow',
      })

      // // console.log('[create-stamped-review] success', await response.json())

      return
    },
    {
      onSuccess: () => {
        setState(initialState)
        setIsCreatingReview(false)
        setCreatedReviewSuccessfully(true)
      },
    }
  )

  return {
    createReview: execute,
    creatingReview: loading,
    createReviewError: error,
    reviewState: state,
    setReviewState: setState,
    isCreatingReview,
    setIsCreatingReview,
    createdReviewSuccessfully,
  }
}
