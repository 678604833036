/** @jsxImportSource theme-ui */
import { Pagination } from '@material-ui/lab'
import React, {
    ComponentProps,
    useMemo,
    useState
} from 'react'
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { useStampedReviews } from 'src/hooks/use-stamped-reviews'
import { Box, Container } from 'theme-ui'
import { LoadingOverlay } from '~/components/Loading-Overlay'
import { ProductQuestionCreator } from '../Product-Question-Creator'
import { ProductQuestionItem } from '../Product-Question-Item'
import { ProductReviewCreator } from '../Product-Review-Creator'
import { ProductReviewItem } from '../Product-Review-Item'
import { ProductReviewSummary } from '../Product-Review-Summary'
import {
    StampedQuestionSchema,
    StampedReviewSchema,
    StampedReviewSummarySchema,
} from './fragments'

type Props = {
  reviews?: StampedReviewSchema[]
  reviewsWithImages?: StampedReviewSchema[]
  summary?: StampedReviewSummarySchema
  questions?: StampedQuestionSchema[]
  productTitle: string
  totalReviewsCount?: number
} & ComponentProps<typeof ProductReviewCreator>

export const ProductReviewsDomId = 'product-reviews'

export const ProductReviews = React.memo(function ProductReviews(props: Props) {
  const {
    reviews: initialReviews,
    summary,
    questions,
    reviewsWithImages,
    productTitle,
    totalReviewsCount = 1,
    ...productReviewOrQuestionCreatorProps
  } = props

  const reviewsPerPage = 5

  const {
    data: reviews,
    isValidating,
    page: reviewsPage,
    setPage: setReviewsPage,
    error,
  } = useStampedReviews(props.productId, {
    initialData: initialReviews,
    count: reviewsPerPage,
  })

  // console.log('[stamped]', { initialReviews, reviews })

  const [category, setCategory] = useState<'reviews' | 'questions'>('reviews')

  // // console.log('[product-reviews]', { questions })

  const renderReviews = useMemo(() => {
    return (
      <Box
        id="creator"
        sx={{ display: category === 'reviews' ? 'block' : 'none' }}
      >
        <ProductReviewCreator {...productReviewOrQuestionCreatorProps} />
        <Box sx={{ position: 'relative' }}>
          <Box sx={{ minHeight: '90vh' }}>
            {reviews?.map((review) => (
              <ProductReviewItem key={review.id} {...review} />
            ))}
          </Box>
          <Box>
            <Container sx={{ paddingX: 3 }}>
              <Pagination
                page={reviewsPage}
                onChange={(_, newPage) => {
                  document?.getElementById('creator')?.scrollIntoView({
                    behavior: 'smooth',
                  })

                  setReviewsPage(newPage)
                }}
                sx={{ margin: 'auto' }}
                count={Math.ceil(totalReviewsCount / reviewsPerPage)}
              />
            </Container>
          </Box>
          {isValidating && <LoadingOverlay />}
        </Box>
      </Box>
    )
  }, [
    category,
    isValidating,
    productReviewOrQuestionCreatorProps,
    reviews,
    reviewsPage,
    setReviewsPage,
    totalReviewsCount,
  ])

  // ADD back if we want questions...
  const renderQuestions = useMemo(() => {
    // // console.log('[product-reviews][render-questions]', questions)
    return (
      <Box sx={{ display: category === 'questions' ? 'block' : 'none' }}>
        <ProductQuestionCreator {...productReviewOrQuestionCreatorProps} />
        {questions?.map(
          (node) =>
            node?.question?.state === 1 && (
              <ProductQuestionItem key={node.question.id} {...node.question} />
            )
        )}
      </Box>
    )
  }, [category, productReviewOrQuestionCreatorProps, questions])

  return (
    <Box id={ProductReviewsDomId}>
      {summary && (
        <ProductReviewSummary
          reviewsWithMedia={reviewsWithImages}
          productTitle={productTitle}
          currentCategory={category}
          onChangeCategory={setCategory}
          {...summary}
        />
      )}
      {renderReviews}
      {/* {renderQuestions} */}
    </Box>
  )
})
