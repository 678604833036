/** @jsxImportSource theme-ui */
import { Flex, Text, Container, Box } from 'theme-ui'
import React, { useCallback, useMemo } from 'react'
import { Styled } from '~/components/styled'
import { ContentfulImage } from '~/features/image/contentful-image'

type Props = {
  handle: string
  title: string
  subtitle: string
  posts?: {
    image?: {
      url: string
    }
    alt?: string
    url?: string
  }[]
}

const Item = React.memo(function InstagramPost(props: {
  image?: {
    url: string
  }
  alt?: string
  url?: string
}) {
  const { image, alt, url } = props
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={url}
      aria-label="Open Instagram Post"
      sx={{
        // position: 'relative',
        // minWidth: ['75%', '40%', '25%'],
        display: 'block',
        minWidth: ['220px', '330px'],
      }}
    >
      <Box
        sx={{
          pb: '100%',
          position: 'relative',
        }}
      >
        {image && (
          <ContentfulImage
            sizes="(min-width: 780px) 330px, 220px"
            alt={alt || 'Instagram Post'}
            loading="lazy"
            src={image?.url}
            fill
            sx={{
              borderRadius: 3,
            }}
          />
        )}
      </Box>
    </a>
  )
})

function InstagramGallery(props: Props) {
  const { handle: untrimmedHandle, title, posts, subtitle } = props

  const handle = untrimmedHandle.replace('@', '')

  const renderPosts = useMemo(() => {
    return posts?.map(({ alt, image, url }, i) => (
      <Item
        alt={alt || "Instagram"}
        image={image}
        url={url}
        key={`${url}-${alt}-${image?.url}-${i}`}
      />
    ))
  }, [posts])

  return (
    <Container
      sx={{ mb: [7, 9], mt: [7], textAlign: 'center', maxWidth: '1500px' }}
    >
      <a
        href={`https://instagram.com/${handle}`}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          variant: 'text.preHeader',
          marginBottom: 3,
          fontWeight: 'bold',
          textDecoration: 'none',
          fontSize: 3,
          display: 'block',
        }}
      >{`@${handle}`}</a>
      {title.trim() && (
        <Styled.h2 sx={{ marginBottom: 2, fontWeight: '600', fontSize: 6 }}>
          {title}
        </Styled.h2>
      )}
      {subtitle?.trim() && <Text>{subtitle}</Text>}
      <Flex
        sx={{
          flexWrap: 'nowrap',
          userSelect: 'none',
          overflowX: 'scroll',
          '::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
          px: 2,
          gap: 3,
          mt: 5,
        }}
      >
        {renderPosts}
      </Flex>
    </Container>
  )
}

export default React.memo(InstagramGallery)
