/** @jsxImportSource theme-ui */
import React, { Fragment, useMemo, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { ShopName } from 'src/constants'
import { empty } from 'src/templates/empty'
import { Box, Flex } from 'theme-ui'
import { ProductReviewsLightbox } from './Product-Reviews-Lightbox'
import { StampedReviewSchema } from './Product-Reviews/fragments'

type Props = {
  reviews?: StampedReviewSchema[]
  productTitle: string
}

export const ProductReviewSummaryMedia = React.memo(
  function ProductReviewSummaryMedia(props: Props) {
    const { reviews, productTitle } = props

    const [modalOpenIndex, setModalOpenIndex] = useState<null | number>(null)

    const images = useMemo(() => {
      const formatted = (reviews
        ?.filter((node) => {
          return node?.reviewUserPhotos
        })
        // .join(',')
        // .split(',')
        .map((item) => ({ type: 'image', ...item })) ??
        empty.array) as (StampedReviewSchema & { type: string })[]

      return formatted
    }, [reviews])

    const videos = useMemo(() => {
      const formatted =
        reviews
          ?.filter((node) => {
            return node?.reviewUserVideos
          })
          // .join(',')
          // .split(',')
          .map((item) => ({ type: 'video', ...item })) ?? empty.array

      return formatted
    }, [reviews])

    // const maxLength = 100
    // let media = images.slice(0, maxLength)

    // if (videos.length) {
    //   media = [...images.slice(0, maxLength - 2), videos[1]]
    // }
    const media = images

    if (!media.length) return null

    // // console.log('[product-review-images]', media)

    // // console.log('[product-review-summary-media]', { reviews })

    return (
      <Fragment>
        <Flex sx={{ flexWrap: 'wrap', px: [0, 2] }}>
          {media.map(({ type, reviewUserPhotos, reviewUserVideos }) => {
            if (type === 'image') {
              const photos: string[] = reviewUserPhotos?.split(',') ?? []
              return photos.map((url: string, i) => (
                <Box
                  sx={{
                    width: '16.5%',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const photos = images
                      .map(
                        (review) => review.reviewUserPhotos?.split(',') ?? []
                      )
                      .reduce((prev, next) => [...prev, ...next], [])

                    const index = photos.findIndex((photo) => photo === url)

                    if (index !== -1) setModalOpenIndex(index)
                  }}
                  key={url}
                >
                  <Box
                    sx={{
                      mx: 2,
                      height: 0,
                      position: 'relative',
                      paddingBottom: '100%',
                      marginTop: 3,
                    }}
                  >
                    <LazyLoadImage
                      src={`//cdn.stamped.io/uploads/photos/${url}`}
                      alt={`${ShopName} ${productTitle} product review`}
                      sx={{
                        marginBottom: 0,
                        borderRadius: 3,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        objectFit: 'cover',
                        height: '100%',
                        width: '100%',
                      }}
                      width={'100%'}
                      height={'100%'}
                    />
                  </Box>
                </Box>
              ))
            }
            return null
          })}
        </Flex>
        {modalOpenIndex !== null && (
          <ProductReviewsLightbox
            data={media
              .filter(({ type }) => {
                return type === 'image'
              })
              .map(({ reviewUserPhotos, reviewMessage, reviewTitle }) => {
                const photos: string[] = reviewUserPhotos?.split(',') ?? []
                return photos.map((photo) => ({
                  src: `//cdn.stamped.io/uploads/photos/${photo}`,
                  reviewMessage,
                  reviewTitle,
                }))
              })
              .reduce((prev, next) => {
                return [...prev, ...next]
              }, [])}
            key={modalOpenIndex}
            initialIndex={modalOpenIndex}
            onClose={() => setModalOpenIndex(null)}
          />
        )}
      </Fragment>
    )
  }
)
