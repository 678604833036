/** @jsxImportSource theme-ui */
import moment from 'moment'
import { Fragment } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { Box, Container, Divider, Flex, Text } from 'theme-ui'
import { Styled } from '~/components/styled'
import { StampedQuestionSchema } from './Product-Reviews/fragments'

type Props = {} & StampedQuestionSchema['question']

export function ProductQuestionItem(props: Props) {
  const { dateCreated, name, state, answersList, message } = props

  const renderAnswers = () => {
    if (!answersList?.length) return null

    return (
      <Box sx={{ marginTop: 3 }}>
        <Divider sx={{ borderWidth: 2, marginBottom: 3 }} color="text" />
        <Box sx={{ display: ['block', 'flex'] }}>
          <Box sx={{ flex: [null, 1], width: [null, '20%'], mb: [4, 0] }}>
            <Flex sx={{ alignItems: 'center' }}>
              <MdCheckCircle sx={{ color: 'secondary', mr: 1, mb: '3px' }} />
              <Box sx={{ flex: 1 }}>
                <Text sx={{ color: 'secondary' }}>
                  Answered ({answersList.length})
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box sx={{ flex: [null, 4], ml: [null, 6] }}>
            {answersList.map((answer, i) => {
              if (!answer) return null

              return (
                <Box
                  sx={{ marginBottom: i + 1 < answersList.length ? 3 : 0 }}
                  key={answer.id}
                >
                  {/* <Flex sx={{ justifyContent: 'space-between' }}>
                    <Styled.h3 sx={{ fontWeight: '600' }}>
                      {answer.name}
                    </Styled.h3>
                  </Flex> */}
                  <Text>
                    <strong>Answer: </strong>
                    <span
                      dangerouslySetInnerHTML={{ __html: answer.message ?? '' }}
                    />
                    {/* {answer.message} */}
                  </Text>
                  <Text sx={{ mt: 1, color: 'gray' }}>
                    - {answer.name}, {moment(answer.dateCreated).fromNow()}
                  </Text>
                </Box>
              )
            })}
          </Box>
        </Box>
      </Box>
    )
  }

  // https://imgur.com/a/TcGiX0h
  // only show items with state === 1, the others are directly emailed to customers.
  if (state !== 1) return null
  //   // console.log('[Product-Question-Item] props', props)

  return (
    <Fragment>
      <Container sx={{ paddingX: 3 }}>
        <Box
          sx={{
            p: [3, 4],
            bg: 'background',
            borderRadius: 3,
            borderStyle: 'solid',
            mb: 3,
          }}
        >
          <Flex sx={{ display: ['block', 'flex'] }}>
            <Box
              sx={{
                marginRight: [0, 4],
                flex: [null, 1],
                display: ['flex', 'block'],
                justifyContent: ['space-between'],
                marginBottom: [3, 0],
              }}
            >
              <Styled.h3 sx={{ fontWeight: '600' }}>{name}</Styled.h3>
              <Text sx={{ color: 'gray' }}>
                {moment(dateCreated).fromNow()}
              </Text>
            </Box>
            <Box sx={{ marginLeft: [0, 4], flex: [null, 4] }}>
              <Box>
                <Text>
                  <strong>Question: </strong>
                  {message}
                </Text>
              </Box>
            </Box>
          </Flex>
          {renderAnswers()}
        </Box>
      </Container>
    </Fragment>
  )
}
