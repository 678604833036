/** @jsxImportSource theme-ui */
import { useCallback } from 'react'
import { useTheme } from 'src/hooks/use-theme'
import { Box, Container, Flex, Text } from 'theme-ui'
import { Styled } from '~/components/styled'
import { ProductReviewSummaryMedia } from './Product-Review-Summary-Media'
import {
    StampedReviewSchema,
    StampedReviewSummarySchema,
} from './Product-Reviews/fragments'
import { StarRatings } from './Star-Ratings'

type Props = {
  reviewsWithMedia?: StampedReviewSchema[]
  productTitle: string
  onChangeCategory: (newCategory: 'reviews' | 'questions') => void
  currentCategory: 'reviews' | 'questions'
} & StampedReviewSummarySchema

export function ProductReviewSummary(props: Props) {
  // // console.log('[product-review-summary]')
  const {
    breakdown,
    count,
    countQuestions = 0,
    rating: unformattedRating,
    reviewsWithMedia,
    productTitle,
    onChangeCategory,
    currentCategory,
  } = props

  const rating = unformattedRating?.toFixed(1)

  const { colors } = useTheme()

  const renderRatingSummary = useCallback(() => {
    if (!breakdown || count === undefined) return null

    return [...Object.entries(breakdown)]
      .reverse()
      .map(([unparsedStars, ratingCount]) => {
        // // console.log('[product-review-summary]', {
        //   unparsedStars,
        //   ratingCount,
        // })
        if (ratingCount === undefined || unparsedStars === undefined) {
          return null
        }
        // rating1 -> 1
        const stars = unparsedStars.replace('rating', '').trim()
        const ratioOfTotalRatingsForThisStar = ratingCount / count

        return (
          <Flex sx={{ marginY: 2 }} key={unparsedStars}>
            <Box sx={{ flex: [0.3, 0.2], alignSelf: 'center' }}>
              <Text sx={{ color: 'gray' }}>
                {stars} {stars === '1' ? 'Star' : 'Stars'}
              </Text>
            </Box>
            <Box
              sx={{
                flex: 1,
                backgroundColor: 'mutedSecondary',
                height: 2,
              }}
            >
              <Box
                sx={{
                  // height: '50px',
                  width: `${ratioOfTotalRatingsForThisStar * 100}%`,
                  backgroundColor: 'secondary',
                  height: '100%',
                }}
              ></Box>
            </Box>
          </Flex>
        )
      })
  }, [breakdown, count])

  const renderMedia = useCallback(
    () => (
      <ProductReviewSummaryMedia
        reviews={reviewsWithMedia}
        productTitle={productTitle}
      />
    ),
    [productTitle, reviewsWithMedia]
  )

  if (!rating || !breakdown || count === undefined) {
    return null
  }

  return (
    <Box sx={{ marginY: 5 }}>
      <Container
        sx={{
          px: 3,
        }}
      >
        <Box
          sx={{
            p: [3, 4],
            bg: 'background',
            borderRadius: 3,
            borderStyle: 'solid',
          }}
        >
          <Box sx={{ display: ['block', 'flex'] }}>
            <Box sx={{ flex: [null, 2] }}>
              <Flex sx={{ alignItems: 'center' }}>
                <Box
                  sx={{
                    px: [3, 4],
                    py: [2],
                    borderRadius: 3,
                    backgroundColor: 'primary',
                    color: 'background',
                    fontWeight: '600',
                    fontSize: [4, 5],
                    mr: [2, 3],
                  }}
                >
                  <Text>{rating}</Text>
                </Box>
                <Box>
                  <StarRatings
                    rating={parseFloat(rating)}
                    size={30}
                    color={colors.secondary}
                  />
                  <Text sx={{ color: 'gray', mt: 3 }}>
                    Based on {count} {count === 1 ? 'Review' : 'Reviews'}
                  </Text>
                </Box>
              </Flex>

              <Box sx={{ marginY: 4 }}>{renderRatingSummary()}</Box>
            </Box>
            <Box sx={{ flex: [null, 2] }}>{renderMedia()}</Box>
          </Box>
        </Box>
        <Box sx={{ display: 'none' }}>
          <Box
            as="button"
            onClick={() => onChangeCategory('reviews')}
            sx={{
              variant: 'styles.button',
              flexGrow: [1, 0],
              paddingTop: 5,
              px: 3,
              borderBottomStyle: 'solid',
              borderColor: currentCategory === 'reviews' ? null : 'transparent',
              transition: 'border .25s ease-in-out',
              '&:hover': {
                borderColor:
                  currentCategory === 'reviews' ? null : 'background',
              },
            }}
          >
            <Styled.h3 sx={{ fontWeight: '600' }}>Reviews ({count})</Styled.h3>
          </Box>
          <Box
            as="button"
            // only open it if the count > 0
            onClick={() => onChangeCategory('questions')}
            sx={{
              variant: 'styles.button',
              flexGrow: [1, 0],
              paddingTop: [4, 5],
              px: 3,
              borderBottomStyle: 'solid',
              borderColor:
                currentCategory === 'questions' ? null : 'transparent',
              transition: 'border .25s ease-in-out',
              '&:hover': {
                borderColor:
                  currentCategory === 'questions' ? null : 'background',
              },
              // borderColor: 'background',
            }}
          >
            <Styled.h3 sx={{ fontWeight: '600' }}>
              Questions ({countQuestions})
            </Styled.h3>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
