export interface ContentfulQuestion {
  questionTitle: string
  weight: number
  allowsMultipleAnswers: boolean
  allowsOpenText: boolean
  handle: string
  answerOptions: {
    items: {
      answerText: string
      answerHandle: string
      // Each string is in the form 'productHandle:VariantName1=VariantValue1,VariantName2=VariantValue2,...'
      products: string[]
    }[]
  }
}
interface ShopifyProduct {
  handle: string
  variants: ShopifyProductVariant[]
}

interface ShopifyProductVariant {
  id: string
  sku: string
  price: string
  selectedOptions: {
    name: string
    value: string
  }[]
}

function parseProductAnswer(productAnswer: string) {
  const [productHandle, variantConditionString] = productAnswer.split(':')
  if (!productHandle) {
    console.error(`Missing product handle in "${productAnswer}"`)
    return
  }
  const variantConditions =
    variantConditionString
      ?.split(',')
      .map((condition) => {
        const [name, value] = condition.split('=')
        return { name, value }
      })
      .filter(({ name, value }) => {
        if (!name || !value) {
          console.error(`Missing variant name or value in "${productAnswer}"`)
          return false
        }
        return true
      }) || []
  return { productHandle, variantConditions }
}

function getQuizResults({
  shopifyProducts,
  contentfulQuestions,
  quizAnswers,
}: {
  shopifyProducts: ShopifyProduct[]
  contentfulQuestions: ContentfulQuestion[]
  quizAnswers: string[][]
}) {
  const productScores: { [variantId: string]: number } = {}

  quizAnswers.forEach((answerArray, questionIndex) => {
    answerArray.forEach((answerText) => {
      const question = contentfulQuestions[questionIndex]
      if (!question) return
      const answerOption = question?.answerOptions.items.find(
        (ans) => ans.answerText === answerText
      )
      if (!answerOption) return

      answerOption.products.forEach((productAnswer) => {
        const parsedProductAnswer = parseProductAnswer(productAnswer)
        if (!parsedProductAnswer) return

        const { productHandle, variantConditions } = parsedProductAnswer

        shopifyProducts.forEach((product) => {
          if (product.handle === productHandle) {
            product.variants.forEach((variant) => {
              const variantMatches = variantConditions.every(
                ({ name, value }) => {
                  return variant.selectedOptions.some(
                    (option) => option.name === name && option.value === value
                  )
                }
              )
              if (variantMatches) {
                productScores[variant.id] =
                  (productScores[variant.id] || 0) + question.weight
              }
            })
          }
        })
      })
    })
  })

  // Create a map of all product variants for efficient lookups
  const variantMap = new Map(
    shopifyProducts.flatMap((product) =>
      product.variants.map((variant) => [variant.id, variant])
    )
  )

  const sortedProductVariants = Object.entries(productScores)
    .sort(([, scoreA], [, scoreB]) => scoreB - scoreA)
    .map(([id]) => variantMap.get(id))

  return sortedProductVariants
}

type VariantResult = ShopifyProductVariant & {
  productHandle: string
  score: number
}

export function getQuizResults2({
  shopifyProducts,
  contentfulQuestions,
  quizAnswerHandleArrays,
  preferDeduplicatedProductHandles = true,
}: {
  shopifyProducts: ShopifyProduct[]
  contentfulQuestions: ContentfulQuestion[]
  quizAnswerHandleArrays: string[][]
  /**
   * default: `true`
   */
  preferDeduplicatedProductHandles?: boolean
}) {
  const allVariants: VariantResult[] = shopifyProducts.flatMap((product) =>
    product.variants.map((variant) => ({
      ...variant,
      productHandle: product.handle,
      score: 0,
    }))
  )

  quizAnswerHandleArrays.forEach((answerHandleArrays, questionIndex) => {
    const contentfulQuestion = contentfulQuestions[questionIndex]

    if (!contentfulQuestion) return
    // match all by default
    let matchingProductVariantsForQuestion = new Map<string, VariantResult>()

    answerHandleArrays.forEach((answerHandle) => {
      const contentfulAnswerOption =
        contentfulQuestion?.answerOptions.items.find(
          (ans) => ans.answerHandle === answerHandle
        )

      contentfulAnswerOption?.products.forEach((conditionString) => {
        // * -> all products
        // !* -> no products
        // & separated conditions where the key is either product-handle=HANDLE or variant:NAME=VALUE

        if (conditionString === '*') {
          matchingProductVariantsForQuestion = new Map(
            allVariants.map((v) => [v.id, v])
          )
        } else if (conditionString === '!*') {
          matchingProductVariantsForQuestion = new Map()
        } else if (conditionString) {
          const conditions = conditionString.split('&')

          allVariants.forEach((variant) => {
            const variantMatches = conditions.every((condition) => {
              const isProductHandleCondition =
                condition.startsWith('product-handle=') ||
                condition.startsWith('product-handle!=')
              const isVariantCondition = condition.startsWith('variant:')

              if (isProductHandleCondition) {
                const productHandle = condition.split('=').pop()

                const isNegated = condition.startsWith('product-handle!=')

                if (isNegated) {
                  return variant.productHandle !== productHandle
                }

                return variant.productHandle === productHandle
              } else if (isVariantCondition) {
                const [, variantConditionString] = condition.split(':')

                if (!variantConditionString) return false
                const isNegated = variantConditionString.includes('!=')

                if (isNegated) {
                  const [name, value] = variantConditionString.split('!=')

                  if (!name || !value) return false

                  return variant.selectedOptions.every((option) => {
                    return option.name !== name || option.value !== value
                  })
                }
                const [name, value] = variantConditionString.split('=')

                if (!name || !value) return false

                return variant.selectedOptions.some((option) => {
                  return option.name === name && option.value === value
                })
              }
            })

            if (variantMatches) {
              matchingProductVariantsForQuestion.set(variant.id, variant)
            }
          })
        }
      })
    })

    matchingProductVariantsForQuestion.forEach((variant) => {
      const weight = contentfulQuestion.weight ?? 1

      const allVariantIndex = allVariants.findIndex((v) => v.id === variant.id)

      if (!allVariants[allVariantIndex]) return

      allVariants[allVariantIndex]!.score += weight
    })
  })

  allVariants.sort((a, b) => b.score - a.score)

  if (preferDeduplicatedProductHandles) {
    const seenProductHandles = new Set<string>()
    return allVariants.filter((variant) => {
      if (seenProductHandles.has(variant.productHandle)) return false
      seenProductHandles.add(variant.productHandle)
      return true
    })
  }

  return allVariants
}
