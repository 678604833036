export function submitQuizResultsToToKlaviyo({
  email,
  quizAnswers,
  quizRecommendedProductHandles,
  isSurvey,
}: {
  email: string
  quizAnswers: {
    [questionHandle: string]: string[]
  }
  quizRecommendedProductHandles: string[] | undefined
  isSurvey: boolean
}) {
  const QUIZ_LIST_ID = 'WpZizQ'  
  const SURVEY_LIST_ID = 'T9Vd4D'

  const listId = isSurvey ? SURVEY_LIST_ID : QUIZ_LIST_ID;

  console.log("🚀 Submitting to Klaviyo...");
  console.log("➡️ isSurvey:", isSurvey);
  console.log("📌 Using list ID:", listId);

  return fetch(
    `https://a.klaviyo.com/client/subscriptions/?company_id=${process.env.NEXT_PUBLIC_KLAVIYO_SITE_ID}`,
    {
      method: 'POST',
      headers: { revision: '2025-01-15', 'content-type': 'application/json' },

      body: JSON.stringify({
        data: {
          type: 'subscription',
          attributes: {
            custom_source: isSurvey ? 'Survey Response' : 'Quiz Response',
            profile: {
              data: {
                type: 'profile',
                attributes: {
                  email,
                  properties: {
                    response_type: isSurvey ? "survey" : "quiz",
                    [`${isSurvey ? "survey" : "quiz"}:recommended-products`]: quizRecommendedProductHandles?.join(","),
                    ...Object.entries(quizAnswers).reduce(
                      (cur, [questionHandle, answers]) => ({
                        ...cur,
                        [`${isSurvey ? "survey" : "quiz"}:${questionHandle}`]: answers.join(","),
                      }),
                      {}
                    ),
                  },
                  subscriptions: {
                    email: {
                      marketing: {
                        consent: 'SUBSCRIBED',
                      },
                    },
                  },
                },
              },
            },
          },
          relationships: {
            list: {
              data: {
                type: 'list',
                id: listId, 
              },
            },
          },
        },
      }),
    }
  )
}
