/** @jsxImportSource theme-ui */

import { AnimatePresence, motion } from 'framer-motion'
import {
  ComponentProps,
  Fragment,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { MdArrowBack, MdChevronRight } from 'react-icons/md'
import { Box, Button, Container, Input, Text, useThemeUI } from 'theme-ui'
import { Styled } from '~/components/styled'

export function Quiz(props: Props) {
  const [answeredQuestions, setAnsweredQuestions] = useState<{
    [questionHandle: string]: string[]
  }>({})
  const [submitDone, setSubmitDone] = useState(false)
  const [headerImage, setHeaderImage] = useState(null)
  const [successHeaderImage, setSuccessHeaderImage] = useState(null)
  useEffect(() => {
    const loadHeader = async () => {
      const headerData = await queryContentful(
        `
        query SplitHero($id: String!) {
          splitHeroImageCollection(where: { sys: { id: $id } }) {
            items {
              title
              titleTag
              herosubtitle: subtitle
              image {
                url
                height
                width
              }
              imageIsOnRight
              maxHeight
              minHeight
              contentColor {
                colorArray
              }
              contentBackgroundColor {
                colorArray
              }
            }
          }
        }`,
        {
          id: props.headerBlock.sys.id,
        }
      )
      setHeaderImage(headerData.data.splitHeroImageCollection.items[0])
      const successHeaderData = await queryContentful(
        `
          query SplitHero($id: String!) {
            splitHeroImageCollection(where: { sys: { id: $id } }) {
              items {
                title
                titleTag
                herosubtitle: subtitle
                image {
                  url
                  height
                  width
                }
                imageIsOnRight
                maxHeight
                minHeight
                contentColor {
                  colorArray
                }
                contentBackgroundColor {
                  colorArray
                }
              }
            }
          }`,
        {
          id: props.successHeaderBlock.sys.id,
        }
      )
      setSuccessHeaderImage(
        successHeaderData.data.splitHeroImageCollection.items[0]
      )
    }
    loadHeader()
  }, [])
  const isSurvey = useMemo(() => 
    props.questions?.items?.some(q => q?.allowsOpenText) ?? false,
    [props.questions]
  );

  const answeredQuestionsCount = Object.keys(answeredQuestions).length
  const stepIndex = answeredQuestionsCount

  const hasAnsweredAllQuestions =
    props.questions.items.length === answeredQuestionsCount
  const activeQuestion = props.questions.items[stepIndex]

  const allProducts = useAllShopifyProductsforQuiz().data
  const quizResults = useMemo(() => {
    const final =
      allProducts &&
      getQuizResults2({
        contentfulQuestions: props.questions.items,
        quizAnswerHandleArrays: Object.values(answeredQuestions),
        shopifyProducts: allProducts.products,
      })

    return final
  }, [allProducts, answeredQuestions, props.questions.items])

  function goBack() {
    setAnsweredQuestions((answers) => {
      const lastKey = Object.keys(answers).pop()

      if (!lastKey) return answers
      const { [lastKey]: _omit, ...rest } = answers

      return rest
    })
  }

  const percentDone =
    (answeredQuestionsCount / (props.questions.items.length || 1)) * 100

  const header = useMemo(
    () => (
      <Box
        sx={{
          position: 'sticky',
          top: HeaderHeight.map((height) => [height, 'px'].join('')),
        }}
      >
        <Box
          sx={{
            height: '5px',
          }}
        >
          <Box
            sx={{
              height: '100%',
              width: '100%',
              transform: `translateX(-${100 - Math.max(5, percentDone)}%)`,
              transition: 'transform 250ms ease-in-out',
              bg: 'text',
            }}
          ></Box>
        </Box>
      </Box>
    ),
    [percentDone]
  )

  const imageHeader = () => {
    if (!submitDone) {
      if (headerImage)
        return (
          <SplitHero
            contentColor={headerImage.contentColor?.colorArray}
            minHeight={headerImage.minHeight}
            maxHeight={headerImage.maxHeight}
            imageIsOnRight={headerImage.imageIsOnRight}
            contentBackgroundColor={
              headerImage.contentBackgroundColor?.colorArray
            }
            title={headerImage.title}
            src={headerImage.image?.url}
            subtitle={headerImage?.herosubtitle}
          />
        )
    } else {
      if (successHeaderImage)
        return (
          <SplitHero
            contentColor={successHeaderImage.contentColor?.colorArray}
            minHeight={successHeaderImage.minHeight}
            maxHeight={successHeaderImage.maxHeight}
            imageIsOnRight={successHeaderImage.imageIsOnRight}
            contentBackgroundColor={
              successHeaderImage.contentBackgroundColor?.colorArray
            }
            title={successHeaderImage.title}
            src={successHeaderImage.image?.url}
            subtitle={successHeaderImage?.herosubtitle}
            imageFocusPosition = {"top"}
          />
        )
    }
  }
  return (
    <quizContext.Provider
      value={{
        answers: answeredQuestions,
        goBack,
        addAnswersToQuestion(questionHandle, answers) {
          setAnsweredQuestions((value) => ({
            ...value,
            [questionHandle]: answers,
          }))
        },
        canGoBack: answeredQuestionsCount > 0,
        submitDone,
        setSubmitDone,
      }}
    >
      {imageHeader()}
      {header}
      <Container
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          px: [3, 4],
        }}
      >
        <AnimatePresence mode="wait">
          {!hasAnsweredAllQuestions && (
            <Fragment key={`step-index=${stepIndex}`}>
              {activeQuestion && <Question question={activeQuestion} />}
            </Fragment>
          )}
          {hasAnsweredAllQuestions && (
            <AfterQuestions
              {...props}
              key="after-questions"
              quizAnswers={answeredQuestions}
              quizResults={quizResults}
              productImageUrlsByHandle={allProducts?.productImageURLsByHandle}
              productDescriptionByHandle={allProducts?.productDescriptionByHandle}
              chargifyDiscountCode={props.chargifyDiscountCode}
              isSurvey={isSurvey}
            />
          )}
        </AnimatePresence>
      </Container>
    </quizContext.Provider>
  )
}

type Props = ContentfulQuiz

const quizContext = createContext({
  answers: {},
  goBack() {},
  addAnswersToQuestion(questionHandle: string, answers: string[]) {},
  canGoBack: false,
  submitDone: false,
  setSubmitDone() {},
})

function BackButton() {
  const { canGoBack, goBack } = useContext(quizContext)

  return (
    <Box
      as="button"
      sx={{
        all: 'unset',
        size: 40,
        m: 2,
        borderRadius: 999,
        bg: 'muted',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px solid`,
        borderColor: 'border',
        cursor: 'pointer',
        opacity: canGoBack ? 1 : 0,
        transition: 'opacity 150ms ease',
        pointerEvents: canGoBack ? 'all' : 'none',
      }}
      onClick={goBack}
    >
      <MdArrowBack size={20} />
    </Box>
  )
}

function Question({
  question,
}: {
  question: Props['contentfulQuestions'][number]
}) {
  const { addAnswersToQuestion, goBack, canGoBack } = useContext(quizContext)

  const [showQuizHelpModal, setShowQuizHelpModal] = useState(false)
  
  const [multipleAnswers, setMultipleAnswers] = useState<Set<string>>(new Set())

  const [isOtherSelected, setIsOtherSelected] = useState(false) 
  const [isSpecifySelected, setIsSpecifySelected] = useState(false)
  const [textAnswer, setTextAnswer] = useState<string>("")

  const timer = useRef<NodeJS.Timeout>()

  // Check if any answer contains "other" or "specify"
  const hasOtherOption = question.answerOptions.items.some(({ answerHandle }) =>
    answerHandle.toLowerCase().includes("other")
  )

  const hasSpecifyOption = question.answerOptions.items.some(({ answerHandle }) =>
    answerHandle.toLowerCase().includes("specify")
  )

  const handleSelect = (answerHandle: string) => {
    if (question.allowsMultipleAnswers) {
      // Multi-selection 
      setMultipleAnswers((current) => {
        const next = new Set(current)

        if (next.has(answerHandle)) {
          next.delete(answerHandle)
        } else {
          next.add(answerHandle)
        }

        return next
      })
    } else {
      // Single-selection 
      setMultipleAnswers(new Set([answerHandle]))
    }

    // Handle "Other" toggle
    if (answerHandle.toLowerCase().includes("other")) {
      setIsOtherSelected((prev) => !prev) 
      if (!isOtherSelected) setTextAnswer("") 
    } else {
      setIsOtherSelected(false) 
    }

    // Handle "Specify" case (textarea is **always visible**)
    if (answerHandle.toLowerCase().includes("specify")) {
      setIsSpecifySelected(true)
    } else {
      setIsSpecifySelected(false)
    }

    // Submit immediately if single choice
    if (!question.allowsMultipleAnswers) {
      if (timer.current) clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        addAnswersToQuestion(question.handle, [answerHandle])
      }, 150)
    }
  }

  const handleTextSubmit = () => {
    if (textAnswer.trim()) {
      addAnswersToQuestion(question.handle, [...multipleAnswers, textAnswer])
    }
  }

  const isOnlySpecify = question.answerOptions.items.length === 1 && 
  question.answerOptions.items[0].answerText.toLowerCase().includes("specify");

  const isSubmitDisabled = (isOtherSelected || isSpecifySelected ||isOnlySpecify) && textAnswer.trim() === ""

  const isOnlyOtherSelected = multipleAnswers.size === 1 && [...multipleAnswers][0].toLowerCase().includes("other");
  const isContinueDisabled = isOnlyOtherSelected && textAnswer.trim() === "";

  return (
    <Box sx={{ gap: "32px", flexDirection: "column", display: "flex", flex: 1 }}>
      <Header>
        <BackButton />

        {question.allowsMultipleAnswers && multipleAnswers.size > 0 && (
          <motion.div initial={{ x: 10, opacity: 0 }} animate={{ x: 0, opacity: 1 }}>
            <Button
              onClick={() => addAnswersToQuestion(question.handle, Array.from(multipleAnswers))}
              disabled={isContinueDisabled}
              sx={{
                bg: isContinueDisabled ? "gray" : "secondary",
                borderColor: isContinueDisabled ? "gray" : "secondary",
                cursor: isContinueDisabled ? "not-allowed" : "pointer",
                opacity: isContinueDisabled ? 0.6 : 1,
                display: "flex",
                alignItems: "center",
                height: "44px",
                px: 3,
                gap: 3,
                pl: 4,
              }}
            >
              <Text sx={{ lineHeight: "44px" }}>Continue</Text>{" "}
              <MdChevronRight size={24} />
            </Button>
          </motion.div>
        )}
      </Header>

      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "32px" }}>
        <motion.div
          sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9, y: -10 }}
        >
          <Styled.h2 sx={{ textAlign: "center", mb: 0 }}>{question.questionTitle}</Styled.h2>
          {question.helpText && (
            <Text
              sx={{ color: "#19b4eb", fontSize: "14px", cursor: "pointer" }}
              onClick={() => setShowQuizHelpModal(true)}
            >
              {question.helpText}
            </Text>
          )}
          {question.allowsMultipleAnswers && (
            <Box sx={{ textAlign: "center", color: "gray" }}>Select at least one.</Box>
          )}
        </motion.div>

        <Box sx={{ gap: 3, flexDirection: "column", display: "flex" }}>
          {question.answerOptions.items.map(({ answerText, answerHandle }, i) => {
            const isSelected = multipleAnswers.has(answerHandle)

            return (
              <motion.div
                key={answerText}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ delay: !multipleAnswers.size ? 0 : i * 0.1 }}
                sx={{ display: "flex" }}
              >
                <Button
                  sx={{
                    bg: isSelected ? "text" : "background",
                    color: isSelected ? "background" : "text",
                    flex: 1,
                    "&:hover": { bg: isSelected ? "text" : "gray", color: "background" },
                    transform: isSelected && !question.allowsMultipleAnswers ? `transform(scale(1.1))` : undefined,
                    transition: `transform 150ms ease`,
                  }}
                  disabled={isOnlySpecify}
                  onClick={() => !isOnlySpecify && handleSelect(answerHandle)}
                >
                  {answerText}
                </Button>
              </motion.div>
            )
          })}

          {/* Show textarea when "Other" is toggled or "Specify" is selected */}
          {(isOtherSelected || isSpecifySelected || hasSpecifyOption) && question.allowsOpenText && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
              <textarea
                value={textAnswer}
                onChange={(e) => setTextAnswer(e.target.value)}
                placeholder="Type your answer here..."
                style={{
                  padding: "10px",
                  fontSize: "16px",
                  borderRadius: "8px",
                  border: "1px solid #ccc",
                  minHeight: "80px",
                  resize: "vertical",
                }}
              />
              <Button onClick={handleTextSubmit} sx={{ bg: isSubmitDisabled ? "gray" : "primary", color: "white", cursor: isSubmitDisabled ? "not-allowed" : "pointer"}} disabled={isSubmitDisabled}>
                Submit
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      <QuizHelpModal
        isOpen={showQuizHelpModal}
        onClose={() => setShowQuizHelpModal(false)}
        title={question.helpText}
        description={question.helpDescription}
      />
    </Box>
  )
}

export default Question


import { z } from 'zod'
import { HeaderHeight } from '~/components/Header/types'

const EmailSchema = z.string().email()

const validateEmail = (email: string) => {
  const validationResult = EmailSchema.safeParse(email)

  if (!validationResult.success) {
    return false
  }

  return true
}

function Header({ children }: { children: React.ReactNode }) {
  return (
    <header
      sx={{
        flexDirection: 'row',
        position: 'sticky',
        top: HeaderHeight.map((h) => `${h + 5}px`),
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {children}
    </header>
  )
}

function Section({ children }: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 3,
        flexDirection: 'column',
        alignSelf: 'center',
        textAlign: 'center',
        width: '100%',
        maxWidth: 700,
      }}
    >
      {children}
    </Box>
  )
}

import { useAsyncCallback } from 'react-async-hook'
import ConfettiExplosion from 'react-confetti-explosion'
import { useGlobalAppProps } from '~/api/GlobalAppPropsContext'
import { queryContentful } from '~/api/services'
import { LoadingOverlay } from '~/components/Loading-Overlay'
import QuizHelpModal from '~/components/Quiz-Help-Modal'
import StoreContext from '~/context/StoreContext'
import { UIContext } from '~/context/UIContext'
import CollectionProductCard from '~/features/contentful-sections/Collection-Product-Card'
import { submitQuizResultsToToKlaviyo } from '~/features/contentful-sections/quiz/klaviyo'
import { getQuizResults2 } from '~/features/contentful-sections/quiz/results'
import { useAllShopifyProductsforQuiz } from '~/features/contentful-sections/quiz/use-all-products-for-quiz'
import { ContentfulQuiz } from '~/features/contentful-sections/types'
import { SplitHero } from '../Split-Hero'

function AfterQuestions(
  props: Props & {
    quizAnswers: {
      [questionHandle: string]: string[]
    }
    quizResults: ReturnType<typeof getQuizResults2> | undefined
    productImageUrlsByHandle:
      | NonNullable<
          ReturnType<typeof useAllShopifyProductsforQuiz>['data']
        >['productImageURLsByHandle']
      | undefined
    productDescriptionByHandle:
      | NonNullable<
          ReturnType<typeof useAllShopifyProductsforQuiz>['data']
        >['productDescriptionByHandle']
      | undefined
    isSurvey: boolean
  }
) {
  const { isSurvey } = props
  const { setSubmitDone } = useContext(quizContext)
  const [hasSubmittedEmail, setHasSubmittedEmail] = useState(false)
  const [email, setEmail] = useState('')

  const colors = useThemeUI().theme.colors

  const { addDiscount, setChargifyDiscountCode } = useContext(StoreContext)
  const submitEmail = useAsyncCallback(
    async (params: Parameters<typeof submitQuizResultsToToKlaviyo>[0]) => {
      return await submitQuizResultsToToKlaviyo({...params, isSurvey: isSurvey,}).then(async (r) => {
        if (props.discountCode) {
          await addDiscount(props.discountCode)
        }
        if (props.chargifyDiscountCode) {
          setChargifyDiscountCode(props.chargifyDiscountCode)
        }
        setHasSubmittedEmail(true)
        setSubmitDone(true)

        return r
      })
    }
  )
  
  const getLastStepTextType = isSurvey ? "Enter your email to unlock your discount code." : "Enter your email to unlock your discount code & see your quiz results.";
 
  if (!hasSubmittedEmail) {
    const isValidEmail = validateEmail(email)
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 4,
          flex: 1,
        }}
      >
        <Header>
          <BackButton />
        </Header>
        <Section>
          <motion.div
            initial={{
              opacity: 0,
              y: -10,
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
          >
            <Styled.h2>Last step!</Styled.h2>
            <Text>
              {getLastStepTextType}
            </Text>
          </motion.div>

          <motion.div
            initial={{
              opacity: 0,
              y: 10,
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              delay: 0.1,
            }}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Box
              sx={{
                maxWidth: 400,
                width: '100%',
                gap: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Input
                placeholder="What's your email?"
                autoFocus
                type="email"
                autoComplete="email"
                sx={{
                  borderColor: 'transparent',
                  borderWidth: '3px',
                  borderRadius: '12px',
                  width: '100%',
                  bg: 'muted',
                  '&:focus': {
                    borderColor: 'transparent',
                  },
                  background: `padding-box linear-gradient(${colors?.muted}, ${colors?.background}),
                  border-box linear-gradient(${colors?.secondary}, #9fe6ff)`,
                  outline: 'none',
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {isValidEmail && (
                <motion.div
                  initial={{
                    opacity: 0,
                    y: 5,
                  }}
                  animate={{
                    opacity: 1,
                    y: 0,
                  }}
                >
                  <Button
                    onClick={() => {
                      submitEmail.execute({
                        email,
                        quizAnswers: props.quizAnswers,
                        quizRecommendedProductHandles: props.quizResults
                          ?.slice(3)
                          .map((r) => r.productHandle),
                        isSurvey: props.isSurvey,
                      })
                    }}
                    sx={{
                      width: '100%',
                      opacity: submitEmail.loading ? 0.5 : 1,
                    }}
                    disabled={submitEmail.loading}
                  >
                    Submit!
                  </Button>
                </motion.div>
              )}
            </Box>
          </motion.div>
        </Section>
      </Box>
    )
  }

  return <Done {...props} isSurvey = {isSurvey} />
}

function Done({
  discountText,
  discountCode,
  quizResults,
  productImageUrlsByHandle,
  productDescriptionByHandle,
  questions,
  isSurvey,
}: ComponentProps<typeof AfterQuestions> & { isSurvey: boolean }) {
  const scrollToDivRef = useRef<HTMLDivElement>(null)

  const getDiscountTextType = isSurvey ? " " : "Discount will be automatically applied at checkout.";
  const getClaimProductsType = isSurvey ? " " : "Claim your products";
  const getAllToCartType = isSurvey ? "Start Shopping" : "Add All to Cart";

  const { addVariantToCart, isUpdatingLineItem, addMultipleVariantsToCart } =
    useContext(StoreContext)
  const {} = useContext(UIContext)

  const addAllToCartMutation = useAsyncCallback(addMultipleVariantsToCart)

  const { allShopifyProduct, allStampedReviewSummaries } =
    useGlobalAppProps() || {}

  const productHandleMap = new Map(allShopifyProduct?.map((p) => [p.handle, p]))

  useEffect(function scroll() {
    scrollToDivRef.current?.scrollIntoView({
      behavior: 'instant',
      inline: 'end',
    })
    window.scrollBy(0, -60)
  }, [])

  if (!quizResults) {
    return (
      <Box sx={{ minHeight: '500px', flex: 1, position: 'relative' }}>
        <LoadingOverlay />
      </Box>
    )
  }

  const resulsToDisplay = 3

  const displayedResults = quizResults
    ?.slice(0, resulsToDisplay)
    .filter((result) => productHandleMap.get(result.productHandle))

  return (
    <>
      <div ref={scrollToDivRef} />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <ConfettiExplosion
          {...{
            force: 0.8,
            duration: 3000,
            particleCount: 400,
            width: 1600,
          }}
        />
      </Box>
      <Box
        sx={{
          py: '72px',
          flexDirection: 'column',
          display: 'flex',
          gap: '32px',
          textAlign: 'center',
          width: '100%',
        }}
      >
        {discountCode && discountText ? (
          <motion.div
            initial={{
              opacity: 0,
              transform: 'scale(.9)',
            }}
            animate={{
              opacity: 1,
              transform: 'scale(1)',
            }}
            transition={{
              duration: 1.5,
              delay: 0.5,
            }}
            style={{
              alignSelf: 'center',
            }}
          >
            <Box
              sx={{
                p: 4,
                borderRadius: '12px',
                bg: 'secondary',
                color: 'white',
              }}
            >
              <Styled.h3 sx={{ color: 'inherit' }}>{discountText}</Styled.h3>
              <Text>{getDiscountTextType}</Text>
            </Box>
          </motion.div>
        ) : null}
        <Box
          sx={{
            width: '100%',
            als: 'center',
            maxWidth: 1200,
            position: 'relative',
          }}
        >
          <Styled.h2>{getClaimProductsType}</Styled.h2>
          <Styled.p>We found the perfect products, just for you.</Styled.p>
          <Box
            sx={{ flexDirection: 'row', justifyContent: 'center', mb: '32px' }}
          >
            <Button
              onClick={async () => {
                addAllToCartMutation.execute(
                  displayedResults.map((result) => ({
                    variantId: result.id,
                    quantity: 1,
                  }))
                )
              }}
              disabled={addAllToCartMutation.loading}
              sx={{
                opacity: addAllToCartMutation.loading ? 0.5 : 1,
              }}
            >
              {getAllToCartType}
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px',
              color: 'text',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            {displayedResults.map((quizResult) => {
              const product = productHandleMap.get(quizResult.productHandle)!

              const reviewsSummary = allStampedReviewSummaries?.find(
                ({ handle }) => handle && handle === product.handle
              )
              const images = productImageUrlsByHandle?.[product.handle]
              const description = productDescriptionByHandle?.[product.handle]
              return (
                <Box
                  sx={{
                    marginBottom: 4,
                    width: ['calc(50% - 32px)', 'calc(33% - 32px)'],
                  }}
                  key={quizResult.id}
                >
                  <CollectionProductCard
                    key={quizResult.id}
                    handle={product.handle}
                    ctaText="Add to Cart"
                    title={product.title}
                    price={quizResult.price}
                    imageHeightPercentage={['130%']}
                    images={
                      images?.map((src) => ({
                        src: src,
                        alt: product.title,
                      })) ?? []
                    }
                    maxHeight={['300px']}
                    onClick={() => {
                      addVariantToCart({
                        variantId: quizResult.id,
                        quantity: 1,
                      })
                    }}
                    // maxHeight={undefined}
                    showReviewsCount={!!reviewsSummary?.count}
                    reviewsCount={reviewsSummary?.count}
                    starRating={reviewsSummary?.rating}
                  />
                  <Text sx={{ color: 'gray', fontSize: 1 }}>
                    {description}
                  </Text>
                </Box>
              )
            })}
          </Box>

          {(!!isUpdatingLineItem || addAllToCartMutation.loading) && (
            <Box
              sx={{
                position: 'absolute',
                inset: 0,
                zIndex: 10,
                pointerEvents: 'none',
              }}
            >
              <LoadingOverlay />
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}
